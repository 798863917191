import React, { useEffect } from "react";
import { attachmentsManager, spendingsManager } from "../../../../shared/core/service/services";
import {
	ADD_OR_EDIT_SPENDING,
	REMOVE_ATTACHMENT_MODAL_ID,
	REMOVE_SPENDING_MODAL_ID,
} from "../../../core/modal/modal-id";
import SpendingForm, { BlankSpending } from "./components/spending-form";

import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Amount } from "../../../../shared/core/amount/amount";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { getUrlFromLink } from "../../../../shared/domains/BaseUrl";
import { Attachment } from "../../../../shared/domains/accounting-transaction/transaction/attachment";
import { AttachmentsLinks } from "../../../../shared/domains/accounting-transaction/transaction/attachments-links";
import { SpendingLinks } from "../../../../shared/domains/accounting-transaction/transaction/spending-links";
import { AccountingTransaction } from "../../../../shared/domains/accounting-transaction/transaction/transaction";
import { TransactionLinks } from "../../../../shared/domains/accounting-transaction/transaction/transaction-links";
import { useAttachments } from "../../../../shared/domains/accounting-transaction/transaction/use-attachments";
import { useSpendings } from "../../../../shared/domains/accounting-transaction/transaction/use-spendings";
import { useTransaction } from "../../../../shared/domains/accounting-transaction/transaction/use-transaction";
import { useAttachmentLinks } from "../../../../shared/domains/accounting-transaction/use-attachment-links";
import { useTransactionLinks } from "../../../../shared/domains/accounting-transaction/use-transaction-links";
import { useRTL } from "../../../domain/language/use-rtl";
import { WebImagesConverter } from "../../../utils/web-images-converter";
import AddButtonInputFile from "../../common/add-button-input-file";
import AddButton from "../../common/buttons/add-button";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { ErrorMessage } from "../../common/error-message";
import { Modal } from "../../common/modal/modal";
import { MainColorSpinner } from "../../common/spinner";
import { AddCircleIcon } from "../../common/svg/add-circle-icon";
import { FileDonwloadIcon } from "../../common/svg/file-download";
import { theme } from "../../styles/theme";
import { AddSpendingModal } from "./components/add-spending-modal";
import AttachmentPreview from "./components/attachment-preview";
import EmptyZone from "./components/empty-zone";
import JustificationCard from "./components/justification-card";
import { RemoveAttachmentModal } from "./components/remove-attachment-modal";
import { RemoveSpendingModal } from "./components/remove-spending-modal";
import SpendingPreview from "./components/spending-preview";

const getInitialSpending = (transaction?: AccountingTransaction): BlankSpending => ({
	category: null,
	subcategory: null,
	label: "",
	amount: new Amount(Number(transaction?.amount?.value) / 100, transaction?.amount?.currency || "EUR"),
	additionalData: [],
});

export default function JustifyTransactionScreen(props) {
	const { formatMessage } = useIntl();
	const { location, history } = props;
	const params = location.state;
	const { isRTL } = useRTL();
	const [attachmentError, setAttachmentError] = React.useState<string>("");
	const { canGetContent } = useAttachmentLinks();
	const { transaction, refresh: refreshTransaction } = useTransaction(params.account.id, params.transaction.id);
	const [blankSpending, setBlankSpending] = React.useState<BlankSpending>();

	// const [spendingError, setSpendingError] = React.useState<string>("");
	const [isLoading, setIsLoading] = React.useState<{
		attachment: boolean;
		spending: boolean;
	}>({
		attachment: false,
		spending: false,
	});

	const { canJustify, canAddAttachment, canAddSpending } = useTransactionLinks();

	const { spendings, refresh: refreshSpendings, loading: spendingsLoading } = useSpendings(transaction);
	const { attachments, loading: documentsLoading } = useAttachments(transaction);

	const handleFileChange = async e => {
		const file = e.target.files[0];
		if (transaction && file) {
			const links = transaction.links;
			const fileConverter = new WebImagesConverter();
			const convertedFile = await fileConverter.fileIntoBase64(file);
			setIsLoading({
				...isLoading,
				attachment: true,
			});
			try {
				setAttachmentError("");
				const url = getUrlFromLink(links, TransactionLinks.AddAttachment);
				await attachmentsManager.addAttachment(transaction.id, convertedFile.split(",")[1], url);
			} catch (e) {
				console.log(e);
				setAttachmentError(e.response.data.error.message);
			} finally {
				setIsLoading({
					...isLoading,
					attachment: false,
				});
			}
		}
	};

	const handleOpenFile = async (attachment: Attachment) => {
		if (transaction && canGetContent(attachment)) {
			setIsLoading({
				...isLoading,
				attachment: true,
			});
			try {
				const url = getUrlFromLink(attachment.links, AttachmentsLinks.GetContent);
				const attachmentData = await attachmentsManager.getContent(transaction.id, attachment.id, url);
				if (attachmentData) {
					const file = new Blob([attachmentData.content], { type: attachmentData.contentType });
					// const extension = mime.extension(attachmentData.contentType);
					const fileURL = URL.createObjectURL(file);
					const link = document.createElement("a");
					link.href = fileURL;
					link.target = "_blank";
					// link.download = `Attachment_${attachment.id}.${extension}`;
					link.click();
				}
			} catch (e) {
				setAttachmentError(e.response.data.error.message);
				console.log(e);
			} finally {
				setIsLoading({
					...isLoading,
					attachment: false,
				});
			}
		}
	};

	const handleAddNewSpending = () => {
		setBlankSpending(getInitialSpending(transaction));
	};

	const handleEditSpending = spending => {
		setBlankSpending({
			...cloneDeep(spending),
		});
	};

	const handleSpendingSuccess = () => {
		refreshTransaction();
		refreshSpendings();
		Modal.dismiss(ADD_OR_EDIT_SPENDING);
	};

	useEffect(() => {
		if (blankSpending && transaction) {
			Modal.present(
				ADD_OR_EDIT_SPENDING,
				() => (
					<AddSpendingModal>
						<SpendingForm transaction={transaction} spending={blankSpending} onSuccess={handleSpendingSuccess} />
					</AddSpendingModal>
				),
				{}
			);
		}
	}, [blankSpending]);

	const handleFileDelete = async (attachment: Attachment) => {
		Modal.present(
			REMOVE_ATTACHMENT_MODAL_ID,
			() => <RemoveAttachmentModal onConfirm={() => handleConfirmFileDelete(attachment)} />,
			{
				canBeDismissed: true,
			}
		);
	};

	const handleConfirmFileDelete = async (attachment: Attachment) => {
		if (transaction) {
			setIsLoading({
				...isLoading,
				attachment: true,
			});
			try {
				setAttachmentError("");
				const url = getUrlFromLink(attachment.links, AttachmentsLinks.Delete);
				await attachmentsManager.deleteAttachment(transaction.id, attachment.id, url);
			} catch (e) {
				console.log(e);
				setAttachmentError(e.response.data.error.message);
			} finally {
				setIsLoading({
					...isLoading,
					attachment: false,
				});
			}
		}
	};

	const handleSpendingDelete = async (attachment: Attachment) => {
		Modal.present(
			REMOVE_SPENDING_MODAL_ID,
			() => <RemoveSpendingModal onConfirm={() => handleConfirmSpendingDelete(attachment)} />,
			{
				canBeDismissed: true,
			}
		);
	};

	const handleConfirmSpendingDelete = async (spending: Attachment) => {
		if (transaction) {
			setIsLoading({
				...isLoading,
				spending: true,
			});
			try {
				setAttachmentError("");
				const url = getUrlFromLink(spending.links, SpendingLinks.Delete);
				await spendingsManager.deleteSpendingDetails(transaction.id, spending.id, url);
			} catch (e) {
				console.log(e);
				setAttachmentError(e.response.data.error.message);
			} finally {
				setIsLoading({
					...isLoading,
					spending: false,
				});
			}
		}
	};

	return (
		<PageWrapper isLoading={isLoading.attachment || isLoading.spending}>
			<Title>{formatMessage("justifyScreen.title")}</Title>
			<Subtitle>{formatMessage("justifyScreen.subtitle")}</Subtitle>
			<SectionTitle>{formatMessage("justifyScreen.attachments.title")}</SectionTitle>
			{!!attachmentError && <StyledErrorMessage>{attachmentError}</StyledErrorMessage>}
			{isLoading.attachment && <StyledMainColorSpinner />}
			{documentsLoading ? (
				<StyledMainColorSpinner />
			) : !attachments.length ? (
				transaction &&
				canJustify(transaction) &&
				canAddAttachment(transaction) && (
					<EmptyZone
						isDropZone
						disabled={documentsLoading}
						onChange={handleFileChange}
						title={formatMessage("justifyScreen.attachments.sectionTitle")}
						subtitle={formatMessage("justifyScreen.attachments.sectionSubtitle")}
						icon={<FileDonwloadIcon />}
					/>
				)
			) : (
				<>
					{attachments.map((attachment, index) => (
						<JustificationCard
							key={index}
							isRTL={isRTL}
							onOpen={() => handleOpenFile(attachment)}
							onDelete={() => handleFileDelete(attachment)}
						>
							<AttachmentPreview attachment={attachment} transaction={transaction} />
						</JustificationCard>
					))}
					{transaction && canJustify(transaction) && canAddAttachment(transaction) && (
						<AddButtonInputFile onChange={handleFileChange} disabled={documentsLoading}>
							{formatMessage(
								attachments.length === 0
									? "justifyScreen.attachments.addButtonLabel"
									: "justifyScreen.attachments.addOtherButtonLabel"
							)}
						</AddButtonInputFile>
					)}
				</>
			)}
			{((transaction?.locked && spendings.length > 0) || !transaction?.locked) && (
				<>
					<SectionTitle>{formatMessage("justifyScreen.spendings.title")}</SectionTitle>
					{/* {!!spendingError && <StyledErrorMessage>{spendingError}</StyledErrorMessage>} */}
					{isLoading.spending && <StyledMainColorSpinner />}
					{spendingsLoading ? (
						<StyledMainColorSpinner />
					) : !spendings.length &&
					  transaction &&
					  !transaction.locked &&
					  canJustify(transaction) &&
					  canAddSpending(transaction) ? (
						<EmptyZone
							title={formatMessage("justifyScreen.spendings.sectionTitle")}
							subtitle={formatMessage("justifyScreen.spendings.sectionSubtitle")}
							disabled={spendingsLoading}
							onClick={handleAddNewSpending}
							icon={<AddCircleIcon />}
						/>
					) : (
						<>
							{spendings.map((spending, index) => (
								<JustificationCard
									variant="spending"
									key={index}
									isRTL={isRTL}
									onDelete={transaction?.locked ? undefined : () => handleSpendingDelete(spending)}
									onEdit={transaction?.locked ? undefined : () => handleEditSpending(spending)}
								>
									<SpendingPreview spending={spending} />
								</JustificationCard>
							))}
							{transaction && !transaction.locked && canJustify(transaction) && canAddSpending(transaction) && (
								<AddButton disabled={spendingsLoading} onClick={handleAddNewSpending}>
									{formatMessage(
										attachments.length === 0
											? "justifyScreen.spendings.addButtonLabel"
											: "justifyScreen.spendings.addOtherButtonLabel"
									)}
								</AddButton>
							)}
						</>
					)}
				</>
			)}
			<DoneButton size="M" onClick={() => history.goBack()}>
				{formatMessage("justifyScreen.done")}
			</DoneButton>
		</PageWrapper>
	);
}

const PageWrapper = styled.div<{ isLoading: boolean }>`
	width: 100%;
	${({ isLoading }) => {
		if (isLoading) {
			return `
				pointer-events: none;
				opacity: 0.5;
			`;
		}
		return "";
	}}
`;

const Title = styled.span`
	font-size: 1.5625rem;
	${theme.boldText};
	color: ${theme.mainColor};
	white-space: pre;
`;

const Subtitle = styled(Title)`
	display: block;
	margin-bottom: 40px;
	line-height: 32px;
	height: auto;
	flex-grow: 0;
	color: black;
	overflow-wrap: break-word;
	white-space: pre-line;
`;

const SectionTitle = styled.h2`
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 16px;
`;

const StyledMainColorSpinner = styled(MainColorSpinner)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin-bottom: 16px;
`;

const DoneButton = styled(PrimaryButton)`
	min-width: 250px;
	margin: 40px auto 0;
`;
