import { RadioSelector, RadioSelectorType } from "../forms/radio-selector";
import React, { useEffect } from "react";

import { DisableValueText } from "./disable-value-text";
import { FormSelectInput } from "../../../../shared/core/data-forms/form-input-types";
import { SelectInput } from "../forms/select-input";
import styled from "styled-components";

export const SelectField: React.FC<{
	input: FormSelectInput;
	onChange: (value: string) => void;
	disabled?: boolean;
	type: "radio" | "combobox";
	hasError?: boolean;
}> = ({ input, onChange, type, disabled, hasError = false }) => {
	useEffect(() => {
		if (!input.value && input.values) {
			onChange?.(input.values?.[0].id);
		} else if (!input.values) {
			console.warn("SelectField: input.values is empty", input.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return input.disabled || disabled ? (
		<DisableValueText>{getSelectedOptionLabel(input)}</DisableValueText>
	) : type === "radio" ? (
		<StyledRadioSelector
			innerId="select"
			values={new Map<string, string>(input.values?.map(({ id, label }) => [id, label]))}
			value={input.value}
			onChange={value => onChange && onChange(value || "")}
		/>
	) : (
		// type === "combobox"
		<SelectInput
			style={{ width: "100%" }}
			innerId={input.id}
			key={input.id}
			required={input.required}
			options={input.values?.map(x => x.id)}
			value={findValue(input)}
			onChange={value => onChange && onChange(value + "")}
			itemRenderer={v => input.values?.find(x => v == x.id)?.label ?? ""}
			hasError={hasError}
		/>
	);
};

function findValue(input: FormSelectInput) {
	if(!input.value) {
		console.warn('input.value is empty for KYC:', input.id);
	}
	return input.values?.find(v => v.id.toString() === input.value?.toString())?.id || null
}

function getSelectedOptionLabel(input: FormSelectInput) {
	const option = input.values?.find(v => v.id === input.value);
	if (option) {
		return option.label;
	}
	return null;
}

const StyledRadioSelector = styled<RadioSelectorType<string | undefined>>(RadioSelector)`
	flex-direction: column;

	> *:not(:last-child) {
		margin-bottom: 10px;
	}
`;
