import { Transaction } from "./transaction/transaction";
import { TransactionLinks } from "./transaction/transaction-links";
import { featuresManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";

export const useTransactionLinks = () => {
	const features = useObservable(featuresManager.features);

	const getLinks = (transaction?: Transaction) => 
		// Filter to keep only expenses
		// if (transaction && transaction.amount && transaction.amount.value > 0) {
		// 	return [];
		// }

		 transaction?.links ?? []
	;

	const isOutgoing = (transaction: Transaction) => Number(transaction.amount?.value) < 0;
	
	const canViewJustification = (transaction: Transaction) =>  (canGetSpendings(transaction) || canGetAttachments(transaction)) && isOutgoing(transaction);

	const canJustify = (transaction: Transaction) => {
		if (!features.manageCategorizationSpendings) {
			return false;
		}
		return (!transaction.justified || !transaction.qualified) && isOutgoing(transaction);
	};


	const canShowJustifyLabel = (transaction: Transaction) => {
		if (!features.manageCategorizationSpendings) {
			return false;
		}
		if(transaction.locked) {
			return false
		}
		return (!transaction.justified || !transaction.qualified) && isOutgoing(transaction);
	};

	const canEditJustification = (transaction: Transaction) => {
		if (!features.manageCategorizationSpendings) {
			return false;
		}

		const links = getLinks(transaction);
		return (
			links.findIndex(
				link => link.rel === TransactionLinks.GetSpendings || link.rel === TransactionLinks.GetAttachments
			) > -1
		);
	};

	const canShowAttachments = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return (
			links.findIndex(
				link => link.rel === TransactionLinks.AddAttachment || link.rel === TransactionLinks.GetAttachments
			) > -1
		);
	};

	const canShowSpendings = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return (
			links.findIndex(link => link.rel === TransactionLinks.AddSpending || link.rel === TransactionLinks.GetSpendings) >
			-1
		);
	};

	const canGetSpendings = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return links.findIndex(link => link.rel === TransactionLinks.GetSpendings) > -1;
	};

	const canGetAttachments = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return links.findIndex(link => link.rel === TransactionLinks.GetAttachments) > -1;
	};

	const canAddSpending = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return links.findIndex(link => link.rel === TransactionLinks.AddSpending) > -1;
	};

	const canAddAttachment = (transaction: Transaction) => {
		const links = getLinks(transaction);
		return links.findIndex(link => link.rel === TransactionLinks.AddAttachment) > -1;
	};

	return {
		canJustify,
		canShowJustifyLabel,
		canEditJustification,
		canShowAttachments,
		canShowSpendings,
		canGetSpendings,
		canGetAttachments,
		canAddSpending,
		canAddAttachment,
		canViewJustification
	};
};
