import { UploadTypeAllowed } from "../../../web/ui/common/forms/file-uploader";
import { UrlLink } from "../../domains/BaseUrl";
import { Amount } from "../amount/amount";
import { CurrencyIdentifier } from "../currency/currency";
import { Duration, Periodicity } from "./time-types";
import { Validator } from "./validator";

export enum FormInputType {
	Amount = "AMOUNT",
	Text = "TEXT",
	Bool = "BOOL",
	Date = "DATE",
	DateTime = "DATE_TIME",
	Duration = "DURATION",
	Email = "EMAIL",
	File = "FILE",
	FileContent = "FILE_CONTENT",
	Collection = "COLLECTION",
	Image = "IMAGE",
	Int = "INT",
	Ip = "IP",
	MultiSelect = "MULTI_SELECT",
	Number = "NUMBER",
	Percent = "PERCENT",
	Password = "PASSWORD",
	Phone = "PHONE",
	Rate = "RATE",
	Select = "SELECT",
	SelectWithAmount = "SELECT_WITH_AMOUNT",
	Pincode = "PINCODE",
	Autocomplete = "AUTOCOMPLETE",
}

export type FormInput =
	| FormAmountInput
	| FormTextInput
	| FormBoolInput
	| FormDateInput
	| FormDateTimeInput
	| FormDurationInput
	| FormEmailInput
	| FormImageInput
	| FormCollectionInput
	| FormFileInput
	| FormIntInput
	| FormIpInput
	| FormMultiSelectInput
	| FormNumberInput
	| FormPercentInput
	| FormPasswordInput
	| FormPhoneInput
	| FormRateInput
	| FormSelectInput
	| FormSelectWithAmountInput
	| FormPincodeInput
	| FormAutocompleteInput;

export interface FormInputBase<T> {
	id: string;
	label?: string;
	description?: string;
	required?: boolean;
	value?: T;
	validators?: Validator[];
	disabled?: boolean;
	invalid?: boolean;
}

export interface FormAmountInput extends FormInputBase<Amount> {
	type: FormInputType.Amount;
	currencies?: CurrencyIdentifier[];
	defaultCurrency: CurrencyIdentifier;
}

export interface FormBoolInput extends FormInputBase<boolean> {
	type: FormInputType.Bool;
}

export interface FormCollectionInput extends FormInputBase<never> {
	type: FormInputType.Collection;
	inputs: FormInput[];
}

export interface FormDateInput extends FormInputBase<string> {
	type: FormInputType.Date;
	format: string;
}

export interface FormDateTimeInput extends FormInputBase<string> {
	type: FormInputType.DateTime;
	format: string;
}

export interface FormDurationInput extends FormInputBase<Duration> {
	type: FormInputType.Duration;
	periodicities: Periodicity;
}

export interface FormEmailInput extends FormInputBase<string> {
	type: FormInputType.Email;
}

export interface FormFileInput extends FormInputBase<ExistingFileValue | string> {
	name: string;
	type: FormInputType.File;
	maxSize: number;
	acceptedTypes: string[];
}

export interface FormImageInput extends FormInputBase<ExistingFileValue | string> {
	type: FormInputType.Image | FormInputType.FileContent;
	maxSize: number;
	acceptedTypes: UploadTypeAllowed[];
	minimumPageCount?: number;
	maximumPageCount?: number;
	values?: string[];
	links?: UrlLink[];
}

export interface ExistingFileValue {
	documentId: string;
}

export interface FormIntInput extends FormInputBase<number> {
	type: FormInputType.Int;
}

export interface FormIpInput extends FormInputBase<string> {
	type: FormInputType.Ip;
}

export interface FormMultiSelectInput extends FormInputBase<string[]> {
	type: FormInputType.MultiSelect;
	values: { id: string; label: string }[];
}

export interface FormNumberInput extends FormInputBase<number> {
	type: FormInputType.Number;
}

export interface FormPasswordInput extends FormInputBase<string> {
	type: FormInputType.Password;
}

export interface FormPercentInput extends FormInputBase<number> {
	type: FormInputType.Percent;
}

export interface FormPhoneInput extends FormInputBase<string> {
	type: FormInputType.Phone;
}

export interface FormPincodeInput extends FormInputBase<string> {
	type: FormInputType.Pincode;
}

export interface FormRateInput extends FormInputBase<never> {
	type: FormInputType.Rate;
	inputs: { value: FormNumberInput; periodicity: FormSelectInput };
}

export interface FormSelectInput extends FormInputBase<string> {
	type: FormInputType.Select;
	values: { id: string | number; label: string }[];
}

export interface FormSelectWithAmountInput extends FormInputBase<string> {
	type: FormInputType.SelectWithAmount;
	values: { id: string; label: string; amount: Amount }[];
}

export interface FormTextInput extends FormInputBase<string> {
	type: FormInputType.Text;
}

export type FormInputWithHierarchy = FormInput & { hierarchy: string };

export type FormCollectionInputWithHierarchy = Omit<FormCollectionInput, "inputs"> & {
	hierarchy: string;
	inputs: FormInputWithHierarchy[];
};

export interface FormAutocompleteInput extends FormInputBase<string> {
	type: FormInputType.Autocomplete;
}
