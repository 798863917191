import { theme } from "../../styles/theme";

import React, { useEffect } from "react";
import styled from "styled-components";
import { useRTL } from "../../../domain/language/use-rtl";
import { ChevronDown } from "../svg/chevron-down";

export interface SelectInputProps<T> {
	innerId: string;
	value: T | null;
	options?: readonly T[];
	disabledOptions?: readonly T[];
	onChange: (v: T) => void;
	label?: string;
	placeholder?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	required?: boolean;
	autoFocus?: boolean;
	disabledArrow?: boolean;
	className?: string;
	itemRenderer?: (item: T) => string;
	selectStyle?: React.CSSProperties;
	withPointer?: boolean;
	hasError?: boolean;
}
export type SelectInputType<K> = React.FC<SelectInputProps<K>>;
export function SelectInput<T>({
	innerId: id,
	label,
	onChange,
	placeholder,
	disabledArrow,
	options,
	disabledOptions,
	value,
	className,
	style,
	selectStyle,
	withPointer,
	hasError = false,
	itemRenderer = v => `${v}`,
	...rest
}: SelectInputProps<T>): JSX.Element {
	let selectedIndex: number | undefined;
	if (value !== null) {
		const valueStr = itemRenderer(value);
		const index = options?.findIndex(it => itemRenderer(it) === valueStr);
		if (index && index >= 0) {
			selectedIndex = index;
		}
	}

	const { isRTL } = useRTL();

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (options) {
			onChange(options[+e.currentTarget.value]);
		}
	};

	useEffect(() => {
		if (!options) {
			console.warn("SelectInput: options is empty", id);
		}
	}, [options]);

	return (
		<SelectContainer className={className} hasLabel={!!label} style={style}>
			{label && <Label htmlFor={id}>{label}</Label>}
			<SelectWrapper>
				<StyledSelect
					name={id}
					id={id}
					onChange={handleChange}
					value={selectedIndex !== undefined ? selectedIndex : placeholder}
					style={selectStyle}
					$isRTL={isRTL}
					$hasError={hasError}
					withPointer={withPointer}
					{...rest}
				>
					{placeholder && !value ? <option disabled>{placeholder}</option> : null}
					{options?.map((v, index) => (
						<option
							style={{ width: "auto" }}
							key={index}
							value={index}
							disabled={disabledOptions?.includes(v) || undefined}
						>
							{itemRenderer(v)}
						</option>
					))}
				</StyledSelect>
				{!disabledArrow ? <ChevronDownIcon $isRTL={isRTL} /> : null}
			</SelectWrapper>
		</SelectContainer>
	);
}
const SelectContainer = styled.div<{ hasLabel: boolean }>`
	display: inline-flex;
	flex-direction: ${props => (props.hasLabel ? "column" : "row")};
	flex-shrink: 1;
	flex-grow: 1;
	background-color: #ffffff;
	border-radius: 10px;
`;
const Label = styled.label`
	font-weight: 600;
	color: #000000;
	margin-bottom: 10px;
	font-size: 1rem;
`;
const SelectWrapper = styled.div`
	position: relative;
	flex-shrink: 1;
	flex-grow: 1;
`;
export const StyledSelect = styled.select<{ $isRTL: boolean; withPointer?: boolean; $hasError?: boolean }>`
	overflow: hidden;
	font-family: inherit;
	appearance: none;
	-webkit-appearance: none;
	font-size: 0.875rem;
	${theme.mediumText};
	color: ${props => (props.$hasError ? "red" : "black")};
	flex-shrink: 0;
	padding: ${props => (props.$isRTL ? `15px 17px 14px 30px` : `15px 30px 14px 17px`)};
	border-radius: 8px;
	border: none;
	background-color: white;
	appearance: none;
	outline: 0;
	width: 100%;
	&:disabled {
		color: darkgray;
		background: #f4f4f4;
	}
	${props =>
		props.withPointer
			? `
	:hover {
		cursor: pointer;
		pointer-events: auto;
	`
			: null}
	}
`;
const ChevronDownIcon = styled(ChevronDown)<{ $isRTL: boolean }>`
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: ${props => (props.$isRTL ? `unset` : `10px`)};
	left: ${props => (props.$isRTL ? `10px` : `unset`)};
	transform: translateY(-50%);
	width: 15px;
`;
